/**
 * Re-implementation of https://github.com/tadeegan/locale-currency/
 * 
 * This code is a bit silly, but I'm simply aiming to be compatible with
 * the existing behaviour of `locale-currency`.
 */

import { localeCurrencyMap } from './constants';

/**
 * Attempt to return the country code in a given locale string.
 * 
 * @param {string} localeString 
 * @returns {string} countryCode
 */
export function getCountryCode(localeString) {
    if (!localeString) {
        return null;
    }

    const countryCode = localeString.split('-')[1] || localeString.split('_')[1];
    return countryCode || null;
}

/**
 * Fetch the currency code for a given country code or locale string;
 * 
 * @param {string} localeString
 * @returns {string} currencyCode
 */
export function getCurrency(localeString) {
    const countryCode = getCountryCode(localeString);

    if (!countryCode) {
        return null;
    }

    return localeCurrencyMap.get(countryCode.toUpperCase()) || null;
}

/**
 * Returns an array of country codes that match the given currency code.
 * 
 * @param {string} currencyCode 
 * @returns {string[]} countryCodes
 */
export function getLocales(currencyCode) {
    const locales = [];
    for (const [countryCode, currency] of localeCurrencyMap) {
        if (currency === currencyCode) {
            locales.push(countryCode);
        }
    }
    return locales;
}