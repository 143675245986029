import { bundleRulesCanAddProduct, googlePlayRulesCanAddProduct } from '@dundle/utils/cart';
import { getCurrency } from '@dundle/utils/locale-currency';
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            detectedRegion: 'locale/detectedRegion',
        }),

        allowsCrossBorder() {
            return this.$brand.allowsCrossBorder(this.brand.slug);
        },

        disallowCrossBorder() {
            return this.$security.isEnabled && !this.allowsCrossBorder;
        },

        locatedInCorrectRegion() {
            // fallback to navigator lang as the 3rd fallback for paysafecard check
            return this.detectedRegion
                ? this.product.regionCode === this.detectedRegion
                : this.product.regionCode.toUpperCase() === this.$route.params.region.toUpperCase();
        },

        // DEV-6173 (https://korsit.atlassian.net/browse/DEV-6173) (12-07-2024): Cryptovoucher and Giftmecrypto should not be available to purchase in the Netherlands
        isBrandBlockedInCountry() {
            const bannedProductForCountry = [
                { brandSlug: 'cryptovoucher', bannedCountries: ['nl'] },
                { brandSlug: 'giftmecrypto', bannedCountries: ['nl'] },
            ];

            const matchedBrand = bannedProductForCountry.find(brand => brand.brandSlug === this.product.brand.slug);

            return matchedBrand && matchedBrand.bannedCountries.includes(this.detectedRegion.toLowerCase());
        },

        hideForMicrosoftProduct() {
            // the different brand slugs off microsoft products
            const microsoftBrands = ['xbox', 'microsoft', 'xbox-game-pass', 'xbox-live-gold'];
            // list of currencies where the microsoft products check should be active
            const shouldCheckMicrosoftProducts = [
                'EUR',
                'PLN',
                'GBP',
                'CHF',
                'CZK',
                'SAR',
                'SEK',
                'NOK',
                'DKK',
                'ILS',
                'ZAR',
                'HUF',
                'TRY',
            ];

            // get currency from users regioncode and fallback if the country is missing from currenciesofcountry json
            let currencyOfCountry;
            try {
                currencyOfCountry = getCurrency(this.$locale.iso);
            } catch (err) {
                currencyOfCountry = getCurrency('en-US');
            }

            let slug;

            if (this.brand) slug = this.brand.slug;

            if (this.activeBrand) slug = this.activeBrand.slug;

            if (this.product && this.product.brand) slug = this.product.brand.slug;

            return microsoftBrands.includes(slug) && shouldCheckMicrosoftProducts.includes(currencyOfCountry);
        },

        showMultipleBrandsBlockedModal() {
            // show modal when google play cannot be added because of the defined rules
            const googlePlayCanBeAdded = googlePlayRulesCanAddProduct({
                cartItems: this.$cart.items,
                product: this.product,
            });

            const bundleCanBeAdded = bundleRulesCanAddProduct({
                cartItems: this.$cart.items,
                product: this.product,
            });

            const showModal = !googlePlayCanBeAdded || !bundleCanBeAdded;
            return showModal;
        },

        // this function will check microsoft brands before placing them in the cart
        isMicrosoftApproved() {
            if (this.hideForMicrosoftProduct) {
                // get currency from users regioncode
                const currencyOfCountry = getCurrency(this.$locale.iso);

                // check if currency of users country is in checklist and check if product currency is the same as the users currency
                return this.product.currencyCode === currencyOfCountry.toLowerCase();
            }
            return true;
        },

        isQuantityRestricted() {
            const paysafecardInCart =
                this.$security.isEnabled &&
                this.$cart.items.some(product => product.productId.includes('paysafecard')) &&
                this.product.productId.includes('paysafecard');

            const uberInCart =
                this.$cart.items.some(product => product.productId.includes('uber')) &&
                this.product.productId.includes('uber');

            const abonInCart =
                this.$cart.items.some(product => product.productId.includes('abon')) &&
                this.product.productId.includes('abon');

            const bundleInCart =
                this.$cart.items.some(product => product.productId.includes('bundle')) &&
                this.product.productId.includes('bundle');

            return uberInCart || paysafecardInCart || abonInCart || bundleInCart;
        },
    },
};
