<template>
    <div v-if="product && showRibbon" class="ribbon-container" :class="containerClass">
        <div class="ribbon-wrapper">
            <nuxt-resource
                lazy
                aria-hidden="true"
                class="ribbon-image"
                :src="ribbonImage"
                :alt="`${occasion} ribbon`"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: ['product'],

    computed: {
        ...mapGetters({
            occasion: 'occasion/getActiveOccasion',
        }),

        containerClass() {
            if (this.occasion && this.showRibbon) return this.occasion;
            return undefined;
        },

        showRibbon() {
            // default occasion & christmas does not have a ribbon
            if (this.occasion === 'default' || this.occasion === 'christmas') {
                return false;
            }

            const slug = this.product.slug || this.product.brand?.slug;

            // paysafecard never has deals
            if (slug === 'paysafecard') {
                return false;
            }

            return slug && this.isOccasionBrand;
        },

        ribbonImage() {
            if (this.occasion === 'blackfriday') {
                return `images/occasion/blackfriday/label-black-friday-2023.svg`;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
/* The ribbons */
.ribbon-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    margin-top: -5px;
    margin-right: -2px;
    z-index: 1;

    display: flex;
    justify-content: flex-end;

    .ribbon-wrapper {
        position: relative;
        width: 50%;
        height: 50%;
    }

    .ribbon-image {
        width: 100%;
    }

    &.blackfriday {
        top: 3px;
        width: calc(100% + 2px); // margin-x of parent
    }
}

.corner-ribbon.sticky {
    position: fixed;
}
</style>
